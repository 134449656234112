.custom-styles {
  --ReactInputVerificationCode-itemWidth: 50px;
  --ReactInputVerificationCode-itemHeight: 50px;
  --ReactInputVerificationCode-itemSpacing: 8px;
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: #0b0b0c;
  font-weight: 600;
  font-family: 'Inter';
  font-size: 24;
  box-shadow: none;
  background-color: white;
  border-radius: 0.5rem;
}

.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: inset 0 0 0 1px blue;
}

.custom-styles .ReactInputVerificationCode__item:after {
  transition: background-color 0.2s ease-out;
}
